/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { click, win, wrong } from "../libs/soundEffects";

const AcharItem = ({ config }) => {
  const qtdRodadas = config.rodadas ? config.rodadas.length : 0;

  const [rodadaAtual, setRodadaAtual] = useState({});
  const [numRodadaAtual, setNumRodadaAtual] = useState(0);
  const [respostaOk, setRespostaOk] = useState(false);
  const [selecionados, setSelecionados] = useState([]);
  const [arraySelecionado, setArraySelecionado] = useState([]);

  useEffect(() => {
    if (config.audioInicio) {
      const audio = new Audio(config.audioInicio);

      audio.play();
    }

    if (qtdRodadas > 0) iniciarRodada(1);
  }, []);

  const iniciarRodada = (numero) => {
    const rodada = config.rodadas[numero - 1];

    if (!rodada) return;

    if (rodada.audioEnunciado) {
      const audio = new Audio(rodada.audioEnunciado);

      if (rodada.atrasoAudio) {
        setTimeout(() => audio.play(), rodada.atrasoAudio * 1000);
      } else {
        audio.play();
      }
    }

    setNumRodadaAtual(numero);
    setRodadaAtual(rodada);
    setRespostaOk(false);
    setSelecionados([]);
    setArraySelecionado([]);
  };

  const avancar = () => {
    click();

    if (numRodadaAtual === qtdRodadas) {
      return config.avancar ? config.avancar() : alert("Fim!");
    } else {
      iniciarRodada(numRodadaAtual + 1);
    }
  };

  const responder = (numBloco) => {
    if (rodadaAtual.blocosResposta.includes(numBloco)) {
      if (rodadaAtual.multipla) {
        const opcSelecionadas = [...selecionados];

        if (!selecionados.includes(numBloco)) opcSelecionadas.push(numBloco);

        win();

        if (opcSelecionadas.length === rodadaAtual.blocosResposta.length) {
          setRespostaOk(true);
        } else {
          setSelecionados(opcSelecionadas);
        }
      } else {
        win();

        setRespostaOk(true);
      }
    } else {
      wrong();
    }
  };

  const responderArray = (numBloco) => {
    const objetosValidar = rodadaAtual.objetos.filter((o) =>
      o.blocosResposta.includes(numBloco)
    );

    if (objetosValidar.length === 0) return wrong();

    objetosValidar.forEach((element) => {
      if (rodadaAtual.multipla) {
        if (arraySelecionado.includes(element.id)) {
          return wrong();
        } else {
          if (arraySelecionado.length + 1 === rodadaAtual.objetos.length) {
            setRespostaOk(true);
          } else {
            setArraySelecionado([...arraySelecionado, element.id]);

            return win();
          }
        }
      } else {
        setRespostaOk(true);
        return win();
      }
    });
  };

  const blocos = [];

  let qtd = config.blocosExtra ? 128 : config.blocosMax ? 192 : 16;

  for (let i = 1; i <= qtd; i++) {
    blocos.push(
      <div
        key={i}
        className={"tl7-figura-1 " + i}
        onClick={config.array ? () => responderArray(i) : () => responder(i)}
      ></div>
    );
  }

  return (
    <div className="content">
      <div className="container">
        <div className="box-topo">
          <div className="item-box-topo-1">
            <p className="p-text">{rodadaAtual.enunciado}</p>
          </div>
          <div className="item-box-topo-2">
            <a className="btn-repetir" onClick={() => iniciarRodada(1)}>
              <i className="fas fa-undo-alt"></i> REPETIR
            </a>
          </div>
        </div>
        {rodadaAtual.imagemEnunciado && (
          <div style={{ textAlign: "center" }}>
            <img style={{ height: 160 }} src={rodadaAtual.imagemEnunciado} />
          </div>
        )}
        {rodadaAtual.multipla && !respostaOk && (
          <div className="box-pontuacao">
            <p className="p-text">
              {`${
                rodadaAtual.textoContador
                  ? rodadaAtual.textoContador
                  : "Itens encontrados"
              }: ${
                config.array
                  ? `${arraySelecionado.length}/${rodadaAtual.objetos.length}`
                  : `${selecionados.length}/${rodadaAtual.blocosResposta.length}`
              }`}
            </p>
          </div>
        )}
        {rodadaAtual.palavras && (
          <div className="box-palavras">
            {rodadaAtual.palavras.map((palavra, key) => (
              <div key={key} className="palavra-item">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {palavra}
                </div>
              </div>
            ))}
          </div>
        )}
        {respostaOk ? (
          <div
            className="tl7-box-2"
            style={{
              backgroundImage: `url(${config.imagem})`,
              width: config.imagemLargura ? config.imagemLargura : 524,
              height: config.imagemAltura ? config.imagemAltura : 524,
            }}
          >
            <div
              className="tl7-box-confirmacao"
              style={{
                width: config.imagemLargura ? config.imagemLargura : 524,
                height: config.imagemAltura ? config.imagemAltura : 524,
              }}
            >
              <p className="p-text">PARABÉNS</p>
              <p className="p-text">VOCÊ ACERTOU!</p>
              <a className="btn-concluir" onClick={avancar}>
                CONTINUAR <i className="fas fa-chevron-right"></i>{" "}
              </a>
            </div>
          </div>
        ) : (
          <div
            className={
              config.blocosExtra
                ? "tl7-box-3"
                : config.blocosMax
                ? "tl7-box-4"
                : "tl7-box-2"
            }
            style={{
              backgroundImage: `url(${
                config.imagem ? config.imagem : rodadaAtual.imagem
              })`,
              width: config.imagemLargura ? config.imagemLargura : 524,
              height: config.imagemAltura ? config.imagemAltura : 524,
            }}
          >
            {blocos.map((bloco) => bloco)}
          </div>
        )}
      </div>
    </div>
  );
};

export default AcharItem;

import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import api from "../services/api";
import getUserData from "../services/userData";
import { logout } from "../services/auth";
import SelecaoCor from "../components/SelecaoCor";
import SelecaoPersonagem from "../components/SelecaoPersonagem";
import SelecaoNivel from "../components/SelecaoNivel";
import AppContext from "../container/context";
import ijws from "../services/ijws";
import "../pages/styesLogin.css";

import corFundo from "../assets/imagens/cor-00.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

let userData = {};

const Perfil = ({ history }) => {
  const { notification } = useContext(AppContext);

  const [nome, setNome] = useState("");
  const [perfilNome, setPerfilNome] = useState("");
  const [email, setEmail] = useState("");
  const [assinatura, setAssinatura] = useState({});
  const [formaPagamento, setFormaPagamento] = useState({});
  const [cancelamento, setCancelamento] = useState(false);
  const [avatar, setAvatar] = useState(localStorage.getItem("avatar"));
  const [avatarSelecinado, setAvatarSelecionado] = useState(
    localStorage.getItem("avatar")
  );
  const [cor, setCor] = useState(localStorage.getItem("cor"));
  const [corSelecionada, setCorSelecionada] = useState(
    localStorage.getItem("cor")
  );
  const [alterarAvatar, setAlterarAvatar] = useState(false);
  const [alterarCor, setAlterarCor] = useState(false);
  const [alterarNivel, setAlterarNivel] = useState(false);
  const [nivelId, setNivel] = useState(0);
  const [nivelSelecionadoId, setNivelSelecionado] = useState(0);
  const [usuario, setUsuario] = useState({});
  const [saindo, setSaindo] = useState(false);

  const carregar = useCallback(() => {
    userData = getUserData();

    ijws
      .get("usuarios/carregar")
      .then((usuario) => {
        setUsuario(usuario);
        console.log("usuario", usuario);
        setNome(usuario.nome);
        setPerfilNome(usuario.perfilNome);
        setEmail(usuario.email);
        setNivel(usuario.nivelId);
        setNivelSelecionado(usuario.nivelId);

        if (
          usuario.assinaturaIdentificador &&
          usuario.assinaturaIdentificador != null
        ) {
          listarFormasPagamento(usuario.id);

          const vencimento = new Date(usuario.assinaturaVencimento);

          setAssinatura({
            id: usuario.assinaturaIdentificador,
            vencimento: `${vencimento.getDate()}/${
              vencimento.getMonth() + 1
            }/${vencimento.getFullYear()}`,
            cancelada: usuario.assinaturaCancelada,
            reembolsada: usuario.assinaturaReembolso,
            modalidade: usuario.assinaturaModalidade,
          });
        }
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    carregar();
  }, [carregar]);

  const listarFormasPagamento = (usuarioId) => {
    api
      .post("pagamentos/formas", { usuarioId })
      .then((result) => {
        result.formas
          .filter((f) => f.default)
          .forEach((f) => setFormaPagamento(f));
      })
      .catch((e) => console.log(e));
  };

  const salvar = (e) => {
    e.preventDefault();

    let isOk = true;

    if (nome.length < 3) {
      isOk = false;

      notification("warning", "Nome inválido");
    }

    if (email.length < 5 || !email.includes("@") || !email.includes(".")) {
      isOk = false;

      notification("warning", "Email inválido");
    }

    if (!isOk) return;

    const usuarioSalvar = {
      nome,
      email,
      perfilId: usuario.perfilId,
      perfilNome,
    };

    ijws
      .post("usuarios/editar", usuarioSalvar)
      .then((codes) => {
        return notification("success", "Dados alterados.");

        // if (codes.includes(403))
        //   return notification(
        //     "warning",
        //     "Já existe uma conta com o email informado."
        //   );
      })
      .catch((e) => {
        const { status } = e.response;

        if (status === 1) {
          return notification("error", "Nome inválido");
        }
        if (status === 2) {
          return notification("error", "Email inválido");
        }
        if (status === 3) {
          return notification("error", "Email já em uso");
        }
        console.log(e);
      });
  };

  const sair = () => {
    logout();
    history.push("/");
  };

  const cancelarAssinatura = (e) => {
    e.preventDefault();

    setCancelamento(false);

    api
      .post("pagamentos/cancelamentoAssinatura", {
        usuarioId: userData.usuarioId,
      })
      .then((res) => {
        if (res.codes.includes(200)) {
          notification("success", "Assinatura cancelada.");

          return carregar();
        }

        if (res.codes.includes(600)) {
          notification(
            "error",
            "Não foi possível suspender o pagamento de sua assinatura junto ao Iugu. Tente novamente mais tarde."
          );
          return;
        }

        notification(
          "error",
          "Não foi possível cancelar a assinatura. Tente novamente mais tarde."
        );
      })
      .catch((e) => {
        notification("error", "Não foi possível cancelar sua assinatura.");

        console.log(e);
      });
  };

  const salvarNivel = () => {
    ijws
      .post("perfis/salvarNivel", {
        perfilId: userData.perfilId,
        nivelId: nivelSelecionadoId,
      })
      .then((res) => {
        setNivel(nivelSelecionadoId);
        notification("success", "Nível salvo!");

        return setAlterarNivel(false);
      })
      .catch((e) => {
        notification("error", "Não foi possível alterar o nível.");

        console.log(e);
      });
  };

  if (alterarAvatar)
    return (
      <SelecaoPersonagem
        continuar={() => {
          localStorage.setItem("avatar", avatarSelecinado);
          setAvatar(avatarSelecinado);
          setAlterarAvatar(false);
        }}
        voltar={() => setAlterarAvatar(false)}
        personagemSelecionado={avatarSelecinado}
        selecionarPersonagem={(avatar) => setAvatarSelecionado(avatar)}
      />
    );

  if (alterarCor)
    return (
      <SelecaoCor
        selecionarCor={(cor) => setCorSelecionada(cor)}
        corSelecionada={corSelecionada}
        continuar={() => {
          localStorage.setItem("cor", corSelecionada);
          setCor(corSelecionada);
          setAlterarCor(false);
        }}
        voltar={() => setAlterarCor(false)}
      />
    );

  if (alterarNivel)
    return (
      <SelecaoNivel
        continuar={() => {
          if (nivelSelecionadoId === 0)
            return notification("warning", "Selecione o nível.");

          salvarNivel();
        }}
        voltar={() => {
          setAlterarNivel(false);
          setNivelSelecionado(nivelId);
        }}
        nivelSelecionado={nivelSelecionadoId}
        selecionarNivel={(nivelId) => setNivelSelecionado(nivelId)}
      />
    );

  return (
    <>
      {window.innerWidth > 800 ? (
        <div className="pagina-config">
          <div className="container-login">
            {cancelamento ? (
              <Fragment>
                <h1>Cancelamento de assinatura</h1>
                <p>Deseja realmente cancelar sua assinatura?</p>
                <form className="form-login" onSubmit={cancelarAssinatura}>
                  <input
                    type="submit"
                    className="btn-padrao btn-left"
                    value="Cancelar"
                  />
                  <div
                    id="sair"
                    className="btn-link"
                    onClick={() => setCancelamento(false)}
                  >
                    Voltar
                  </div>
                </form>
              </Fragment>
            ) : (
              <Fragment>
                <h1>PERFIL/CONFIGURAÇÕES</h1>
                <form className="form-login" onSubmit={salvar}>
                  <img
                    alt="Avatar"
                    src={require(`../assets/imagens/${
                      avatares.includes(avatar) ? avatar : "personagem-01.png"
                    }`)}
                    className="personagem img-edit-perfil"
                    onClick={() => setAlterarAvatar(true)}
                    title="Clique para alterar o avatar"
                  />
                  <img
                    alt="Cor"
                    src={require(`../assets/imagens/${retornarImagemCor(cor)}`)}
                    className="img-edit-perfil"
                    onClick={() => setAlterarCor(true)}
                    title="Clique para alterar a cor"
                  />
                  <img
                    alt="Nível"
                    src={
                      nivelId === 1
                        ? require("../assets/imagens/nivel-01.png")
                        : require("../assets/imagens/nivel-02.png")
                    }
                    className="img-edit-perfil"
                    onClick={() => setAlterarNivel(true)}
                    style={{ width: 174, height: 161 }}
                    title="Clique para alterar o nível"
                  />
                  <input
                    type="text"
                    id="nome"
                    placeholder="Nome da criança"
                    value={perfilNome}
                    onChange={(e) => setPerfilNome(e.target.value)}
                  />
                  <input
                    type="text"
                    id="nome"
                    placeholder="Nome do responsável"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                  <input
                    type="text"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  {/* <button
                    id="sair"
                    className="btn-link"
                    onClick={() => setSaindo(true)}
                  >
                    Sair
                  </button> */}

                  {/* <h1>ASSINATURA</h1> */}

                  {assinatura.id && (
                    <Fragment>
                      {assinatura.cancelada ? (
                        <Fragment>
                          <h2>ASSINATURA CANCELADA</h2>
                          {assinatura.reembolsada ? (
                            <p>Assinatura cancelada e reembolsada.</p>
                          ) : (
                            <p>
                              Conteúdo acessível até o fim do período
                              contratado.
                            </p>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          {formaPagamento && (
                            <Fragment>
                              <h2>PAGAMENTO</h2>
                              <p>{formaPagamento.description}</p>
                              <Link
                                to="/app/assinatura"
                                className="btn-padrao btn-left"
                              >
                                Alterar
                              </Link>
                              {assinatura.modalidade === 1 && (
                                <button
                                  id="sair"
                                  className="btn-link"
                                  onClick={() => setCancelamento(true)}
                                >
                                  Cancelar
                                </button>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </form>

                <div>
                  <input
                    type="submit"
                    className="btn-padrao btn-left"
                    value="Salvar"
                    onClick={salvar}
                  />
                  <button
                    id="sair"
                    className="btn-link"
                    onClick={() => setSaindo(true)}
                  >
                    Sair
                  </button>
                </div>

                <div>
                  <br />
                  <br />
                  <h2>FAQ/TUTORIAL</h2>
                  <p>
                    Tem dúvidas sobre a plataforma ou deseja refazer o tutorial?
                    Acesse nossa área de ajuda.
                  </p>
                  <button
                    className="btn-padrao"
                    onClick={() => history.push("/app/ajuda")}
                  >
                    Obter ajuda
                  </button>
                </div>

                <div className="footer-nav">
                  <button
                    className="btn-link ali-left"
                    onClick={() => history.push("/app")}
                  >
                    Voltar
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      ) : (
        <div style={{ background: "white", padding: 10 }}>
          <div
            style={{
              marginBottom: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <h1 style={{ fontSize: "1.2rem" }}>PERFIL/CONFIGURAÇÕES</h1>
              <button
                // style={{ marginTop: 10 }}
                id="sair"
                className="btn-link"
                onClick={() => history.push("/app")}
              >
                &lt; Voltar para o conteúdo
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: 10,
            }}
          >
            <div
              style={{
                width: "110px",
                backgroundImage: `url(${corFundo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
              }}
            >
              <img
                style={{ width: 90 }}
                alt="Avatar"
                src={require(`../assets/imagens/${
                  avatares.includes(avatar) ? avatar : "personagem-01.png"
                }`)}
                onClick={() => setAlterarAvatar(true)}
                title="Clique para alterar o avatar"
              />
            </div>
            <div
              onClick={() => setAlterarCor(true)}
              style={{
                width: "110px",
                backgroundImage: `url(${require(`../assets/imagens/${retornarImagemCor(
                  cor
                )}`)})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
              }}
            ></div>
          </div>
          <div style={{ textAlign: "center" }}>
            <img
              style={{
                marginBottom: "10px",
                width: 144,
                height: 130,
              }}
              alt="Nível"
              src={
                nivelId === 1
                  ? require("../assets/imagens/nivel-01.png")
                  : require("../assets/imagens/nivel-02.png")
              }
              onClick={() => setAlterarNivel(true)}
              title="Clique para alterar o nível"
            />
          </div>
          <form className="form-login" onSubmit={salvar}>
            <input
              style={{ textAlign: "start" }}
              type="text"
              id="nome"
              placeholder="Nome da criança"
              value={perfilNome}
              onChange={(e) => setPerfilNome(e.target.value)}
            />
            <input
              style={{ textAlign: "start" }}
              type="text"
              id="nome"
              placeholder="Nome do responsável"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <input
              style={{ textAlign: "start" }}
              type="text"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input type="submit" className="btn-entrar" value="Salvar" />

            {assinatura.id && (
              <Fragment>
                {assinatura.cancelada ? (
                  <Fragment>
                    <h2>ASSINATURA CANCELADA</h2>
                    {assinatura.reembolsada ? (
                      <p>Assinatura cancelada e reembolsada.</p>
                    ) : (
                      <p>Conteúdo acessível até o fim do período contratado.</p>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {formaPagamento && (
                      <Fragment>
                        <h2>PAGAMENTO</h2>
                        <p>{formaPagamento.description}</p>
                        <Link
                          to="/app/assinatura"
                          className="btn-padrao btn-left"
                        >
                          Alterar
                        </Link>
                        {assinatura.modalidade === 1 && (
                          <button
                            id="sair"
                            className="btn-link"
                            onClick={() => setCancelamento(true)}
                          >
                            Cancelar
                          </button>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}

            <div style={{ textAlign: "center" }}>
              <br />
              <br />
              <h2>FAQ/TUTORIAL</h2>
              <p>
                Tem dúvidas sobre a plataforma ou deseja refazer o tutorial?
                Acesse nossa área de ajuda.
              </p>
              <button
                className="btn-padrao"
                onClick={() => history.push("/app/ajuda")}
              >
                Obter ajuda
              </button>
            </div>
          </form>
          <button
            style={{ marginTop: 10 }}
            id="sair"
            className="btn-link"
            onClick={() => setSaindo(true)}
          >
            Sair
          </button>
        </div>
      )}

      <Dialog
        open={saindo}
        onClose={() => setSaindo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Após sair, para voltar a utilizar a plataforma e ter acesso ao
            conteúdo será necessário efetuar um novo login. Deseja continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              logout();
              history.push("/");
            }}
          >
            Confirmar
          </Button>
          <Button onClick={() => setSaindo(false)} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Perfil;

const retornarImagemCor = (cor) => {
  switch (cor) {
    case "#7B5CA4":
      return "cor-01.png";
    case "#F3910D":
      return "cor-02.png";
    case "#0894CE":
      return "cor-03.png";
    case "#6EC5C7":
      return "cor-04.png";
    case "#2B9533":
      return "cor-05.png";
    case "#E70773":
      return "cor-06.png";
    case "#F49B66":
      return "cor-07.png";
    case "#CB3939":
      return "cor-08.png";
    default:
      return "cor-01.png";
  }
};

const avatares = [
  "personagem-01.png",
  "personagem-02.png",
  "personagem-03.png",
  "personagem-04.png",
  "personagem-05.png",
  "personagem-06.png",
  "personagem-07.png",
  "personagem-08.png",
  "personagem-09.png",
  "personagem-10.png",
  "personagem-11.png",
  "personagem-12.png",
];

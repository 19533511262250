/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import serverHost from "../services/serverHost";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { isMobile } from 'react-device-detect';
import Desenho from "../atividades/Desenho";
import DesenhoM from "../atividades/mobile/desenho";
import MultiplaEscolha from "../atividades/MultiplaEscolha";
import MultiplaEscolhaM from "../atividades/mobile/MultiplaEscolha";
import VisualizarMidia from "../atividades/VisualizarMidia";
import VisualizarMidiaM from "../atividades/mobile/VisualizarMidia";
import AcharItem from "../atividades/AcharItem";
import AcharItemM from '../atividades/mobile/AcharItem';
import Agrupar from "../atividades/Agrupar";
import AgruparM from "../atividades/mobile/Agrupar";
import Memoria from "../atividades/Memoria";
import MemoriaM from "../atividades/mobile/Memoria";
import Nomear from "../atividades/Nomear";
import NomearM from "../atividades/mobile/Nomear";
import SelecionarImagem from "../atividades/SelecionarImagem";
import Escrever from "../atividades/Escrever";
import EscreverM from '../atividades/mobile/Escrever';
import Arrastar from "../atividades/Arrastar";
import ArrastarM from "../atividades/mobile/Arrastar";
import ApertarTecla from "../atividades/ApertarTecla";
import QuebraCabeca from "../atividades/QuebraCabeca";
import Imitar from "../atividades/Imitar";
import Material from "../atividades/Material";
import Cliques from "../atividades/Cliques";
import SeteErros from "../atividades/SeteErros";
import Gif from "../atividades/Gif";
import Genius from "../atividades/Genius";
import Texto from "../atividades/Texto";
import Forca from "../atividades/Forca";
import OuvirClicar from "../atividades/OuvirClicar";
import Ordem from "../atividades/Ordem";
import VisualizarAlternando from "../atividades/VisualizarAlternando";
import EscolherLado from "../atividades/EscolherLado";
import ClicarImitar from "../atividades/ClicarImitar";

import ME2 from "../atividades/MultiplaEscolha2";

import configDesenho from "../atividades/config/desenho";
import configMultiplaEscolha from "../atividades/config/multipla-escolha";
import configAcharItem from "../atividades/config/achar-item";
import configVisualizarMidia from "../atividades/config/visualizar-midia";
import configAgrupar from "../atividades/config/agrupar";
import configMemoria from "../atividades/config/memoria";
import VisualizarEscolher from "../atividades/VisualizarEscolher";
import { CircularProgress } from "@mui/material";

const AtividadeHtml = ({ identificador, avancar, json, toggleScreen }) => {
  const getPlatform = () => {
    let platform = navigator.platform;

    if (platform.includes("Linux") || platform.includes("Android")) {
      return true;
    } else if (platform.includes("Iphone")) {
      return true;
    } else {
      return true;
    }
  };
  const [carregando, setCarregando] = useState({});
  const [configuracoes, setConfiguracoes] = useState({});
  const [numAtividade, setNumAtividade] = useState(0);
  
  const isMobile = window.innerWidth < 800 || window.innerHeight < 400
  
  useEffect(() => {
    setCarregando(true);

    if (json) {
      const parse = JSON.parse(json);
      const diretorio = parse.length > 0 ? parse[0].diretorio : parse.diretorio;

      let responseJson = json;

      responseJson = responseJson.replaceAll(
        "{c}",
        `${serverHost()}/storage/ij-atividades/comum`
      );
      responseJson = responseJson.replaceAll(
        "{comum}",
        `${serverHost()}/storage/ij-atividades/comum`
      );
      responseJson = responseJson.replaceAll(
        "{d}",
        `${serverHost()}/storage/ij-atividades/${diretorio}`
      );
      responseJson = responseJson.replaceAll(
        "{diretorio}",
        `${serverHost()}/storage/ij-atividades/${diretorio}`
      );

      const configuracoes = JSON.parse(responseJson);

      setConfiguracoes(configuracoes);
      setCarregando(false);

      return;
    }

    if (identificador.includes("JSON")) {
      fetch(
        `${serverHost()}/storage/ij-atividades/${
          identificador.split(":")[1]
        }/config.json`
      )
        .then((response) => response.json())
        .then((response) => {
          let responseJson = JSON.stringify(response);

          responseJson = responseJson.replaceAll(
            "{comum}",
            `${serverHost()}/storage/ij-atividades/comum`
          );
          responseJson = responseJson.replaceAll(
            "{c}",
            `${serverHost()}/storage/ij-atividades/comum`
          );
          responseJson = responseJson.replaceAll(
            "{diretorio}",
            `${serverHost()}/storage/ij-atividades/${
              identificador.split(":")[1]
            }`
          );
          responseJson = responseJson.replaceAll(
            "{d}",
            `${serverHost()}/storage/ij-atividades/${
              identificador.split(":")[1]
            }`
          );

          const configuracoes = JSON.parse(responseJson);

          // if(isMobile && (configuracoes.tipo === 'desenho')){
          //     // return avancar();
          // }

          setConfiguracoes(configuracoes);
          setCarregando(false);
        })
        .catch((error) => {
          console.error(error);
          setCarregando(false);
        });
    } else {
      const tipo = identificador.split(";")[0];
      const configuracoes = obterConfiguracoesTipo(
        tipo,
        identificador.split(";")[1]
      );

      configuracoes.tipo = tipo;
      setConfiguracoes(configuracoes);
      setCarregando(false);
    }
  }, []);

  let config = {};

  if (configuracoes.length > 0) {
    config = configuracoes[numAtividade];
    config.avancar =
      numAtividade === configuracoes.length - 1
        ? avancar
        : () => setNumAtividade(numAtividade + 1);
  } else {
    config = configuracoes;
    config.avancar = avancar;
  }

  if (carregando) return <CircularProgress />;
  if (isMobile) {
    switch (config.tipo) {
      case "desenho":
        return <DesenhoM config={config} toggleScreen={toggleScreen} />;
      case "agrupar":
        return <AgruparM config={config} />;

      case "multipla-escolha":
        return <MultiplaEscolhaM config={config} />;

      case "visualizar-midia":
        return <VisualizarMidiaM config={config} />;

      case "achar-item":
        return <AcharItemM config={config} />;

      case "escrever":
        return <EscreverM config={config} />;

      case "quebra-cabeca":
        return <QuebraCabeca config={config} />;

      case "memoria":
        return <MemoriaM config={config} />;

      case "nomear":
        return <NomearM config={config} />;

      case "selecionar-imagem":
        return <SelecionarImagem config={config} />;

      case "arrastar":
        return <ArrastarM config={config} />;

      case "ApertarTecla":
        return <ApertarTecla config={config} />;

      case "Imitar":
        return <Imitar config={config} />;

      case "material":
        return <Material config={config} />;

      case "cliques":
        return <Cliques config={config} />;

      case "erros":
        return <SeteErros config={config} />;

      case "gif":
        return <Gif config={config} />;

      case "genius":
        return <Genius config={config} />;

      case "texto":
        return <Texto config={config} />;

      case "forca":
        return <Forca config={config} />;

      case "ordem":
        return <Ordem config={config} />;

      case "alternar":
        return <VisualizarAlternando config={config} />;

      case "escolher":
        return <EscolherLado config={config} />;

      case "ouvir":
        return <OuvirClicar config={config} />;

      case "clicar":
        return <ClicarImitar config={config} />;

      case "visualizar-escolher":
        return <VisualizarEscolher config={config} />;

      default:
        return <CircularProgress />;
    }
  } else {
    switch (config.tipo) {
      case "agrupar":
        return <AgruparM config={config} />;

      case "desenho":
        return <DesenhoM config={config} />;

      case "multipla-escolha":
        return <MultiplaEscolhaM config={config} />;

      case "visualizar-midia":
        return <VisualizarMidiaM config={config} />;

      case "achar-item":
        return <AcharItem config={config} />;

      case "escrever":
        return <Escrever config={config} />;

      case "quebra-cabeca":
        return <QuebraCabeca config={config} />;

      case "memoria":
        return <MemoriaM config={config} />;

      case "nomear":
        return <NomearM config={config} />;

      case "selecionar-imagem":
        return <SelecionarImagem config={config} />;

      case "arrastar":
        return <ArrastarM config={config} />;

      case "ApertarTecla":
        return <ApertarTecla config={config} />;

      case "Imitar":
        return <Imitar config={config} />;

      case "material":
        return <Material config={config} />;

      case "cliques":
        return <Cliques config={config} />;

      case "erros":
        return <SeteErros config={config} />;

      case "gif":
        return <Gif config={config} />;

      case "genius":
        return <Genius config={config} />;

      case "texto":
        return <Texto config={config} />;

      case "forca":
        return <Forca config={config} />;

      case "ordem":
        return <Ordem config={config} />;

      case "alternar":
        return <VisualizarAlternando config={config} />;

      case "escolher":
        return <EscolherLado config={config} />;

      case "ouvir":
        return <OuvirClicar config={config} />;

      case "clicar":
        return <ClicarImitar config={config} />;

      case "visualizar-escolher":
        return <VisualizarEscolher config={config} />;

      default:
        console.log("Identificador não encontrado");
        return <CircularProgress />;
    }
  }
};

export default AtividadeHtml;

const obterConfiguracoesTipo = (tipo, identificador) => {
  switch (tipo) {
    case "agrupar":
      return configAgrupar(identificador);

    case "desenho":
      return configDesenho(identificador);

    case "multipla-escolha":
      return configMultiplaEscolha(identificador);

    case "visualizar-midia":
      return configVisualizarMidia(identificador);

    case "achar-item":
      return configAcharItem(identificador);

    case "memoria":
      return configMemoria(identificador);

    default:
      return {};
  }
};

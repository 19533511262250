import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";
import SignIn from "./pages/SignIn";
import App from "./pages/App";
import Recuperacao from "./routes/Recuperacao";
import PasswordForgot from "./pages/PasswordForgot";
import SignUp from "./pages/SignUp";
import TokenCartaoTestes from "./pages/TokenCartaoTestes";
import TermosUso from "./pages/TermosUso";
import Desenho from "../src/atividades/mobile/desenho";
// import LoginExterno from "./pages/LoginExterno";
// import Sobre from "./pages/Sobre";
// import SignOut from "./pages/SignOut";
// import ConfirmacaoCadastro from "./pages/ConfirmacaoCadastro";
// import ConfirmacaoAssinatura from "./pages/ConfirmacaoAssinatura";
// import Termos from "./pages/Termos";
// import TesteCanvas from "./routes/TesteCanvas";
// import PageNotFound from "./pages/PageNotFound";

// import TesteVimeo from "./pages/TesteVimeo";

// import AulaExperimental from "./pages/AulaExperimental/AulaExperimental2";
// import AulaExperimentalNivel from "./pages/AulaExperimentalNivel";
// import Promocao from "./pages/Promocao";
import { AppContextProvider } from "./container/context";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/signin", state: { from: props.location } }}
        />
      )
    }
  />
);

const Routes = ({ match }) => (
  <AppContextProvider>
    <BrowserRouter>
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <PrivateRoute path="/app" component={App} />
        <Redirect exact from={`/`} to={`/app`} />
        <Route exact path="/passowrd-forgot" component={PasswordForgot} />
        <Route path="/recuperacao/:token" component={Recuperacao} />
        <Route exact path="/signup" component={SignUp} />
        <Route
          exact
          path="/token-cartao-testes"
          component={TokenCartaoTestes}
        />
        <Route exact path="/desenho" component={Desenho} />
        <Route exact path="/termos-uso" component={TermosUso} />

        {/* <Route exact path="/aula-experimental" component={AulaExperimental} />
      <Route
        exact
        path="/aula-experimental/:nivel"
        component={AulaExperimentalNivel}
      />
      <Route exact path="/assinatura-promocional" component={Promocao} />
      <Route exact path="/login-externo/:servico?" component={LoginExterno} />
      <Route exact path="/passowrd-forgot" component={PasswordForgot} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/signup/:id" component={SignUp} />
      <Route path="/signout" component={SignOut} />
      <Route path="/sobre" component={Sobre} />
      <Route path="/termos-uso" component={Termos} />
      <Route path="/confirmacao-assinatura" component={ConfirmacaoAssinatura} />
      <Route
        path="/confirmacao-cadastro/:token"
        component={ConfirmacaoCadastro}
      />
      <Route path="/recuperacao/:token" component={Recuperacao} />
      <Redirect exact from={`/`} to={`/app`} />
      <PrivateRoute path="/app" component={App} />
      <Route path="/teste-canvas" component={TesteCanvas} />
      <Route path="/teste-vimeo" component={TesteVimeo} />
      <Route path="*" component={PageNotFound} /> */}
      </Switch>
    </BrowserRouter>
  </AppContextProvider>
);

export default Routes;
